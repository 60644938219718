<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            label="ชื่อ"
            class="col-12"
            v-model="formData.name"
            :validations="[
              {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
            ]">
          </sgv-input-text>

          <sgv-input-datetime
            :disabled="$auth.disabled(method)"
            class="col-6"
            label="วันที่เริ่มใช้"
            v-model="formData.startedAt"
            isNull>
          </sgv-input-datetime>

          <sgv-input-datetime
            :disabled="$auth.disabled(method)"
            class="col-6"
            label="วันที่หมดอายุ"
            v-model="formData.expiredAt"
            isNull>
          </sgv-input-datetime>
        </div>

        <div class="form-row">
          <div class="form-group col-12">
            <sgv-input-check
              label="ค่าเริ่มต้น"
              inline
              v-model="formData.isDefault">
            </sgv-input-check>
          </div>
        </div>
      </sgv-form>
    </div>

    <HrDivider :options="tabs" v-model="selected" class="mt-3"></HrDivider>

    <div v-for="tab in tabs" :key="tab.value">
      <component
        v-if="selected === tab.value"
        :label="tab.text"
        :type="tab.type"
        :is="tab.comp"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        :parentId="docConfigId">
      </component>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailGroupInventory from './DetailGroupInventory'
import DetailGroupContact from './DetailGroupContact'
import {
  DETAIL_GROUP,
  CREATE_GROUP,
  UPDATE_GROUP,
  DESTROY_GROUP,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docConfigId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailGroup`,
      formData: {
        name: null,
        startedAt: null,
        expiredAt: null,
        isDefault: false
      },
      isLoaded: false,
      selected: 'สินค้า',
      types: [],
      rFields: ['selected'],
    }
  },
  validations: {
    formData: {
      name: { required }
    }
  },
  computed: {
    tabs () {
      if (this.docConfigId <= 0) return []

      return [
        { text: 'สินค้า', value: 'สินค้า', comp: 'DetailGroupInventory', type: 'item' },
        { text: 'บริการ', value: 'บริการ', comp: 'DetailGroupInventory', type: 'service' },
        { text: 'ลูกค้า', value: 'ลูกค้า', comp: 'DetailGroupContact', type: 'contact' },
      ]
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.group)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isLoaded = true
      })
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'startedAt') {
          this.formData.startedAt = this.$date.format(v.startedAt).datetime
          return
        } else if (key === 'expiredAt') {
          this.formData.expiredAt = this.$date.format(v.expiredAt).datetime
          return
        }

        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      const input = {
        ...v,
        startedAt: v.startedAt ? this.$date.format(v.startedAt).raw : null,
        expiredAt: v.expiredAt ? this.$date.format(v.expiredAt).raw : null
      }
      const removeKeys = []

      removeKeys.forEach(x => delete input[x])

      return input
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docConfigId: res.data.group.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.docConfigId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    selected: 'setRetaining'
  },
  created () {
    if (this.docConfigId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailGroupInventory,
    DetailGroupContact
  }
}
</script>

<style lang="css">
</style>
