<template>
  <div class="">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden, idx}">
        <tr>
          <td v-if="hidden.code">
            {{ idx+1 }}. {{ item.code }}
          </td>
          <td v-if="hidden.name">
            {{ item.name }}
          </td>
          <td v-if="hidden.branch">
            {{ item.branch }}
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { LIST_GROUP_CONTACT } from './graph'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      headers: [
        {text: 'รหัส', value: 'code'},
        {text: 'ชื่อ', value: 'name'},
        {text: 'สาขา', value: 'branch'}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'name', 'branch'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ]
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_GROUP_CONTACT(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docConfigId: this.parentId,
          q: this.getFilter(this.filter)
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    getFilter(v) {
      const filter = {
        ...v,
        params: {
          ...v.params
        }
      }

      return filter
    },
  }
}
</script>

<style lang="css" scoped>
</style>
