var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.method === 'edit')?_c('div',[_c('div',{staticClass:"form-row"},[_c('DetailGroupInventoryInventoryInput',{staticClass:"col-12",attrs:{"label":_vm.label,"docType":_vm.docType,"type":_vm.type,"templateType":_vm.templateType,"select":"id","validations":[
          {text: 'required!', value: _vm.$v.childId.$dirty && !_vm.$v.childId.required}
        ]},model:{value:(_vm.childId),callback:function ($$v) {_vm.childId=$$v},expression:"childId"}}),_c('sgv-input-currency',{staticClass:"col-6",attrs:{"label":"จำนวน","precision":2,"validations":[
          {text: 'required!', value: _vm.$v.formData.qty.$dirty && !_vm.$v.formData.qty.required}
        ]},model:{value:(_vm.formData.qty),callback:function ($$v) {_vm.$set(_vm.formData, "qty", $$v)},expression:"formData.qty"}}),_c('sgv-input-currency',{staticClass:"col-6",attrs:{"label":"ราคา","precision":2,"validations":[
          {text: 'required!', value: _vm.$v.formData.price.$dirty && !_vm.$v.formData.price.required}
        ]},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-datetime',{staticClass:"col-6",attrs:{"label":"วันที่เริ่มใช้","isNull":""},model:{value:(_vm.formData.startedAt),callback:function ($$v) {_vm.$set(_vm.formData, "startedAt", $$v)},expression:"formData.startedAt"}}),_c('sgv-input-datetime',{staticClass:"col-6",attrs:{"label":"วันที่หมดอายุ","isNull":""},model:{value:(_vm.formData.expiredAt),callback:function ($$v) {_vm.$set(_vm.formData, "expiredAt", $$v)},expression:"formData.expiredAt"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"label":"ค่าเริ่มต้น","inline":""},model:{value:(_vm.formData.isDefault),callback:function ($$v) {_vm.$set(_vm.formData, "isDefault", $$v)},expression:"formData.isDefault"}})],1)]),_c('button',{staticClass:"btn btn-success form-group",on:{"click":_vm.createData}},[_vm._v(" เพิ่ม ")])]):_vm._e(),_c('sgv-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options,"rowsPerPage":_vm.rowsPerPage},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
        var hidden = ref.hidden;
return [_c('tr',[(hidden.child)?_c('td',{staticClass:"pre-line"},[(_vm.method === 'edit')?_c('span',{staticClass:"px-1 text-danger pointer",on:{"click":function($event){return _vm.destroyData(item.id)}}},[_c('fa',{attrs:{"icon":"trash"}})],1):_vm._e(),_vm._v(" "+_vm._s(item.child.name)+" ")]):_vm._e(),(hidden.qty)?_c('td',[_vm._v(" "+_vm._s(_vm._f("comma")(item.qty,-1))+" ")]):_vm._e(),(hidden.price)?_c('td',[_vm._v(" "+_vm._s(_vm._f("comma")(item.price,-1))+" "),(item.isDefault)?_c('span',{staticClass:"text-warning"},[_vm._v(" --- ค่าเริ่มต้น ")]):_vm._e(),_c('small',[(item.startedAt)?_c('div',{staticClass:"text-success"},[_vm._v(" วันที่เริ่มใช้: "+_vm._s(_vm._f("datetime")(item.startedAt))+" ")]):_vm._e(),(item.expiredAt)?_c('div',{staticClass:"text-danger"},[_vm._v(" วันที่หมดอายุ: "+_vm._s(_vm._f("datetime")(item.expiredAt))+" ")]):_vm._e()])]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }