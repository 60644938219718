<template>
  <div class="">
    <div v-if="method === 'edit'">
      <div class="form-row">
        <DetailGroupInventoryInventoryInput
          :label="label"
          :docType="docType"
          :type="type"
          :templateType="templateType"
          class="col-12"
          select="id"
          v-model="childId"
          :validations="[
            {text: 'required!', value: $v.childId.$dirty && !$v.childId.required}
          ]">
        </DetailGroupInventoryInventoryInput>

        <sgv-input-currency
          class="col-6"
          label="จำนวน"
          v-model="formData.qty"
          :precision="2"
          :validations="[
            {text: 'required!', value: $v.formData.qty.$dirty && !$v.formData.qty.required}
          ]">
        </sgv-input-currency>

        <sgv-input-currency
          class="col-6"
          label="ราคา"
          v-model="formData.price"
          :precision="2"
          :validations="[
            {text: 'required!', value: $v.formData.price.$dirty && !$v.formData.price.required}
          ]">
        </sgv-input-currency>
      </div>

      <div class="form-row">
        <sgv-input-datetime
          class="col-6"
          label="วันที่เริ่มใช้"
          v-model="formData.startedAt"
          isNull>
        </sgv-input-datetime>

        <sgv-input-datetime
          class="col-6"
          label="วันที่หมดอายุ"
          v-model="formData.expiredAt"
          isNull>
        </sgv-input-datetime>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            label="ค่าเริ่มต้น"
            inline
            v-model="formData.isDefault">
          </sgv-input-check>
        </div>
      </div>

      <button
        class="btn btn-success form-group"
        @click="createData">
        เพิ่ม
      </button>
    </div>

    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :rowsPerPage="rowsPerPage">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.child" class="pre-line">
            <span
              v-if="method === 'edit'"
              class="px-1 text-danger pointer"
              @click="destroyData(item.id)">
              <fa icon="trash"></fa>
            </span>
            {{item.child.name}}
          </td>
          <td v-if="hidden.qty">
            {{item.qty | comma(-1)}}
          </td>
          <td v-if="hidden.price">
            {{item.price | comma(-1)}}
            <span v-if="item.isDefault" class="text-warning">
              --- ค่าเริ่มต้น
            </span>
            <small>
              <div v-if="item.startedAt" class="text-success">
                วันที่เริ่มใช้: {{item.startedAt | datetime}}
              </div>
              <div v-if="item.expiredAt" class="text-danger">
                วันที่หมดอายุ: {{item.expiredAt | datetime}}
              </div>
            </small>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailGroupInventoryInventoryInput from './DetailGroupInventoryInventoryInput'
import {
  LIST_GROUP_INVENTORY,
  CREATE_GROUP_INVENTORY,
  DESTROY_GROUP_INVENTORY,
} from './graph'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      childId: null,
      formData: {
        qty: 0,
        price: 0,
        startedAt: null,
        expiredAt: null,
        isDefault: false
      },
      items: [],
      headers: [
        {text: 'ชื่อ', value: 'child'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา (บาท)', value: 'price'}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['child', 'qty', 'price'],
        column: null,
        search: null,
        toolbar: null,
      },
      rowsPerPage: [
        {text: 'ทั้งหมด', value: null}
      ]
    }
  },
  validations: {
    childId: { required },
    formData: {
      qty: { required },
      price: { required }
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_GROUP_INVENTORY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          type: 'group' + this.$form.capitalize(this.type),
          parentId: this.parentId,
          childId: this.childId,
          q: this.getFilter(this.filter)
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {
        ...v,
        params: {
          ...v.params
        }
      }

      return filter
    },
    serializeInput (input) {
      return {
        ...input,
        startedAt: input.startedAt ? this.$date.format(input.startedAt).raw : null,
        expiredAt: input.expiredAt ? this.$date.format(input.expiredAt).raw : null
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_GROUP_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: 'group' + this.$form.capitalize(this.type),
          parentId: this.parentId,
          childId: this.childId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.items.refetch()
        this.$v.$reset()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData (id) {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          type: 'group' + this.$form.capitalize(this.type),
          parentId: this.parentId,
          docConfigId: id,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.items.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  components: {
    DetailGroupInventoryInventoryInput
  }
}
</script>

<style lang="css" scoped>
</style>
